<template>
  <div class="Home">
    <page-header/>

    <section class="uk-section uk-padding-small uk-margin-top">
      <div class="uk-container" style="min-height: 600px;">
        <div class="uk-width-1-1 uk-margin-bottom">
          <div class="uk-flex uk-flex-right">
            <!--  -->
            <button type="button" class="qbtn_b uk-button"
                    @click="regSur()">
              <span class uk-icon="icon: plus; ratio: 0.7"></span> {{$t("survey.regSur")}}
            </button>
          </div>
        </div>
        <div class="uk-card uk-card-default uk-box-shadow-medium">
          <table class="uk-table uk-table-divider uk-table-responsive uk-table-small">
            <thead>
            <tr>
              <th class="uk-text-center">No</th>
              <th class="uk-text-center" style="width: 190px;">{{$t("survey.title")}}</th>
              <th class="uk-text-center" style="width: 190px;">{{$t("survey.content")}}</th>
              <th class="uk-text-center">{{$t("survey.tQues")}}</th>
              <!-- <th class="uk-text-center">Registrant id</th> -->
              <th class="uk-text-center" colspan="3">{{$t("survey.details")}}</th>
            </tr>
            </thead>
            <tbody class="uk-text-center" v-if="surList.length > 0">
            <tr v-for="(item, index) in surList" :key="index">
              <td>{{index+1}}</td>
              <td>{{item.title}}</td>
              <td>{{item.memo}}</td>
              <td>{{item.qCount}}</td>
              <!-- <td>{{item.id}}</td> -->
              <td style="width: 60px;">
                <button
                        class="qbtn_d uk-width-1-1 uk-button uk-button-small"
                        uk-toggle="target: #detailsModel"
                        @click="surveyDetail(item.id)"
                >{{$t("sdetails")}}</button>
              </td>
              <td style="width: 60px;">
                <button v-if="currentUser.id == item.regUser"
                        class="qbtn_d uk-width-1-1 uk-button uk-button-small"
                        style="background-color: rgb(1, 176, 255); border-color:rgb(1, 176, 255); color:#fff"
                        @click="updateSur(item.id)"
                >{{$t("survey.update")}}</button>
                <span v-else>...</span>
              </td>
              <td style="width: 60px;">
                <button  v-if="currentUser.id == item.regUser"
                        class="qbtn_d uk-width-1-1 uk-button uk-button-small"
                        style="background-color: rgb(245, 76, 76); color: #fff; border-color: rgb(245, 76, 76)" href="#deletePop" uk-toggle
                        @click="beforeDelete(item)"
                >{{$t("survey.delete")}}</button>
                <span v-else>...</span>
              </td>
            </tr>
            </tbody>
            <tbody  v-else>
            <td class="uk-text-center" colspan="7" style="background-color: #E0E3DA;
                color: black"> <h4 >{{$t("survey.noSur")}}</h4></td>
            </tbody>
          </table>
        </div>
      </div>
    </section>
<!--    -->
    <!-- Delete pop -->
    <div id="deletePop" class="uk-flex-top" uk-modal>
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <button class="uk-modal-close-default"  uk-close></button>
        <span class="uk-flex uk-flex-center" uk-icon="icon:info; ratio:3.5"></span>
        <p class="uk-modal-title uk-text-center">
          {{$t("delSur")}}
        </p>
        <div class="uk-width-1-1 uk-margin-remove-left" uk-grid>
          <div class="uk-width-1-4@m">
          </div>
          <div class="uk-width-1-4@m uk-flex uk-flex-center">
            <button type="button" class="uk-button uk-button-danger uk-modal-close"
                    @click="deleteSur()">
              {{ $t('delete') }}
            </button>
          </div>
          <div class="uk-width-1-4@m uk-flex uk-flex-center">
            <button type="button" class="uk-button uk-button-default uk-modal-close">
              {{ $t('cancel') }}
            </button>
          </div>
          <div class="uk-width-1-4@m">
          </div>
        </div>
      </div>
    </div>
    <!-- End pop -->
    <!-- details model -->
    <div id="detailsModel" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default"  uk-close></button>
        <div class="uk-modal-header uk-margin-bottom">
          <h2 class="uk-modal-title uk-text-center">{{surDetails.title}}</h2>
        </div>
        <div class="uk-modal-body uk-padding-remove-top" style="max-height: 600px; overflow: auto"
             v-for="(question,qindex) in surDetails.questions" :key="qindex">
          <div class="uk-grid uk-padding uk-border-rounded uk-box-shadow-medium uk-margin-remove-left">
            <div class="uk-width-auto uk-padding-remove" style="align-self: center;">
              <h1>{{qindex+1}}</h1>
            </div>
            <div class="uk-width-expand">
              <p class="sur-ques">{{question.question}}
                <span v-if="question.type == 0" class="uk-text-primary">{{$t("organization.questionList.choiceA")}}</span>
                <span v-else-if="question.type == 1" class="uk-text-primary">{{$t("organization.questionList.sAnswer")}}</span>
                <span v-else class="uk-text-primary">{{$t("organization.questionList.choiceR")}}</span>
              </p>
              <div class="uk-grid">
                    <span class="uk-child-width-1-4"
                          v-for="(answer,codeIndex) in (question.answers.split('||'))" :key="codeIndex">
                      <span class="surAnswer" v-if="question.answers != ''"><span class="uk-margin-right">{{codeIndex+1}}.</span>{{answer}}</span>
                    </span>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close"
          >{{ $t("close") }}</button>
        </div>
      </div>
    </div>
    <!-- end details model -->
    <!-- model view result section -->
    <!--      <div id="viewResult" class="uk-modal-container" uk-modal>-->
    <!--        <div class="uk-modal-dialog uk-modal-body" style="width: 950px;">-->
    <!--          <div class="qList uk-text-center">{{$t("survey.surTitle")}}</div>-->
    <!--          <ul class="uk-subnav uk-subnav-pill" uk-switcher>-->
    <!--            <li>-->
    <!--              <a href="#">{{$t("survey.vGraph")}}</a>-->
    <!--            </li>-->
    <!--            <li>-->
    <!--              <a href="#">{{$t("survey.vData")}}</a>-->
    <!--            </li>-->
    <!--          </ul>-->
    <!--          <ul class="uk-switcher uk-margin">-->
    <!--            <li>-->
    <!--              <button class="uk-modal-close-default"  uk-close></button>-->
    <!--              <div class="uk-width-1-1 uk-margin-remove-left detailsStudent" uk-grid>-->
    <!--                <div class="uk-width-1-2 uk-text-center">-->
    <!--                  <div class="uk-margin">-->
    <!--                    <label for class="uk-label">{{$t("survey.sname")}}</label>-->
    <!--                    | polaris-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--                <div class="uk-width-1-2 uk-text-center">-->
    <!--                  <label for class="uk-label">{{$t("survey.tName")}} :</label>-->
    <!--                  Pendidikan Agama-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="uk-width-1-1" style="overflow:auto; height: 500px">-->
    <!--                <dl class="uk-width-1-1">-->
    <!--                  <dt>Question 1</dt>-->
    <!--                  <dd class="uk-padding-right uk-text-left percentage percentage-11">-->
    <!--                    <span class="text">question one: 11.33%</span>-->
    <!--                  </dd>-->
    <!--                  <dd class="uk-padding-right uk-text-left percentage percentage-49">-->
    <!--                    <span class="text">question two: 49.77%</span>-->
    <!--                  </dd>-->
    <!--                  <dd class="uk-padding-right uk-text-left percentage percentage-16">-->
    <!--                    <span class="text">question three: 16.09%</span>-->
    <!--                  </dd>-->
    <!--                  <dd class="uk-padding-right uk-text-left percentage percentage-5">-->
    <!--                    <span class="text">question four: 5.41%</span>-->
    <!--                  </dd>-->
    <!--                </dl>-->
    <!--                <dl class="uk-width-1-1">-->
    <!--                  <dt>Question 2</dt>-->
    <!--                  <dd class="percentage percentage-1">-->
    <!--                    <span class="text">question one: 1%</span>-->
    <!--                  </dd>-->
    <!--                  <dd class="percentage percentage-66">-->
    <!--                    <span class="text">question two: 66%</span>-->
    <!--                  </dd>-->
    <!--                  <dd class="percentage percentage-1">-->
    <!--                    <span class="text">question three: 1%</span>-->
    <!--                  </dd>-->
    <!--                  <dd class="percentage percentage-32">-->
    <!--                    <span class="text">question four: 32%</span>-->
    <!--                  </dd>-->
    <!--                </dl>-->
    <!--              </div>-->
    <!--              <div class="uk-modal-footer uk-padding-remove uk-text-right">-->
    <!--                <button-->
    <!--                  class="uk-button uk-button-default uk-modal-close"-->
    <!--                  -->
    <!--                >Cancel</button>-->
    <!--                <button type="button" class="uk-button uk-button-primary" >okay</button>-->
    <!--              </div>-->
    <!--            </li>-->
    <!--            <li>Hello again!</li>-->
    <!--          </ul>-->
    <!--        </div>-->
    <!--      </div>-->
    <!-- end model section -->

    <!-- modal section -->
    <div id="modalsurvey" uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
        <button class="uk-modal-close-default"  uk-close></button>
        <h2 class="qList uk-text-center uk-margin-remove" v-if="updateSurveyModal == false">
          {{$t("survey.regSur")}}</h2>
        <h2 class="qList uk-text-center uk-margin-remove" v-else>{{$t("usurvey")}}</h2>
        <div class="uk-modal-body">
          <div class="uk-width-1-1 uk-margin-remove-left" uk-grid>
            <div class="uk-width-1-3@m uk-padding-remove-left">
              <strong >{{$t("survey.surTitle")}}</strong>
            </div>
            <div class="uk-width-2-3@m uk-padding-remove-left">
              <input
                      type="text"
                      placeholder="Title"
                      v-model="title"
                      class="uk-input uk-form-width-grid uk-from-large"
              />
            </div>
          </div>
          <div class="uk-width-1-1 uk-margin-remove-left" uk-grid>
            <div class="uk-width-1-3@m uk-padding-remove-left">
              <strong >{{$t("survey.surExp")}}</strong>
            </div>
            <div class="uk-width-2-3@m uk-padding-remove-left">
                <textarea class="uk-textarea" v-model="data" rows="3"
                          placeholder="Description"></textarea>
            </div>
          </div>
          <div class="uk-container uk-padding-remove uk-margin-top">
            <ul class="uk-subnav uk-subnav-pill ultypes">
              <template v-for="(item, index) in questions">
                <li :class="{ 'checked': index == activeOption }" :key="index"
                    v-if="item.delete == 'false' || item.delete == false">
                  <a class="uk-button uk-button-primary uk-button-small uk-padding-small"
                     @click="selectType(index)"
                  >{{item.number}}</a>
                </li>
              </template>
              <li>
                <button
                        class="uk-button uk-button-success uk-button-small add-type"
                        @click="addType" v-if="updateSurveyModal == false"
                >
                  <i uk-icon="icon: plus-circle; ratio:1"></i>
                </button>
                <button type="button" v-else
                        class="uk-button uk-button-success uk-button-small add-type"
                        @click="addTypeUpdate">
                  <i uk-icon="icon: plus-circle; ratio:1"></i>
                </button>
              </li>
              <li>
                <button
                        class="uk-button uk-button-danger uk-button-small add-type"
                        style="color: white; background-color: red"
                        @click="removeType"
                >
                  <i uk-icon="icon: trash; ratio:1"></i>
                </button>
              </li>
            </ul>
            <div class="uk-width-1-1">
              <label for="one">
                <input
                        id="one"
                        class="uk-radio"
                        type="radio"
                        name="radiotype"
                        value="0"
                        v-model="questions[activeOption].type"/>
                <span class="uk-margin-left">{{$t("organization.questionList.choiceA")}}</span>
              </label>
              <label for="three" class="uk-margin-left">
                <input
                        id="three"
                        class="uk-radio"
                        type="radio"
                        name="radiotype"
                        value="2"
                        v-model="questions[activeOption].type"/>
                <span class="uk-margin-left">{{$t("organization.questionList.choiceR")}}</span>
              </label>
              <label class="uk-margin-left" for="two">
                <input
                        id="two"
                        class="uk-radio"
                        type="radio"
                        name="radiotype"
                        value="1"
                        v-model="questions[activeOption].type" >
                <span class="uk-margin-left">{{$t("organization.questionList.sAnswer")}}</span>
              </label>
              <div  v-if="questions[activeOption].type == 1">
                <div class="uk-margin-top">
                  <span class="uk-margin-bottom uk-margin-top">{{$t("survey.ques")}}</span>
                  <textarea
                          class="uk-textarea uk-margin-top"
                          v-model="questions[activeOption].question"
                          rows="3"
                          placeholder="Textarea"
                  ></textarea>
                </div>
              </div>
              <div v-else>
                <div class="uk-margin-top">
                  <span class="uk-margin-bottom uk-margin-top">{{$t("survey.ques")}}</span>
                  <textarea
                          class="uk-textarea uk-margin-top"
                          v-model="questions[activeOption].question"
                          rows="3"  placeholder="Textarea">
                  </textarea>
                </div>
                <div class="uk-width-1-1 uk-grid uk-margin-remove-left uk-margin-remove-top uk-margin-remove-bottom"
                        v-for="(item, iex) in questions[activeOption].answers" :key="iex">
                  <div class="uk-child-width-auto uk-padding-small">
                    <span class="uk-text-center uk-flex uk-flex-middle">{{iex+1}}</span>
                  </div>
                  <div class="uk-child-width-expand uk-padding-small uk-padding-remove-left" >
                    <span class="uk-text-center uk-flex uk-flex-middle">{{$t("survey.ans")}}</span>
                  </div>
                  <div class="uk-child-width-expand uk-padding-small uk-padding-remove-left" >
                    <input
                      type="text"
                      class="uk-input uk-form-small"
                      v-model="questions[activeOption].answers[iex].answer"
                      style="width:264px" />
                  </div>
                  <div  class="uk-child-width-auto uk-padding-small uk-padding-remove-left"  >
                      <span uk-icon="icon:plus-circle; ratio: 1.6"
                              @click="addAnswer()"
                      ></span>
                  </div>
                  <div class="uk-child-width-auto uk-padding-small uk-padding-remove-left" >
                      <span uk-icon="icon:minus-circle; ratio: 1.6"
                              @click="removeAnswer(iex)"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-modal-close uk-margin-right">
            {{ $t("cancel") }}</button>
          <button type="button" class="uk-button uk-button-primary uk-modal-close"
                  v-if="updateSurveyModal == false" @click="createSurvey()">
            <span>{{ $t("studentHome.enrollment") }}</span>
          </button>
          <button type="button" class="uk-button uk-button-primary uk-modal-close"
                  v-else @click="modifySurvey()">
            <span>{{ $t("organization.stuReg.update") }}</span>
          </button>
        </div>
      </div>
    </div>
    <page-footer/>
    <!-- end modal section -->
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import ResearchService from "../../services/ResearchService";
  import UIkit from 'uikit'
  import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
  const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")

  export default {
    name: "app",
    components: {
      PageHeader,
      PageFooter
    },
    computed: {
      ...mapGetters(["isLoggedIn", "currentUser"])
    },
    data() {
      return {
        title: "",
        data: "",
        questions: [
          {
            id: '',
            number:1,
            question: "",
            delete: false,
            type: 0,
            answers: [
              {
                answer: ""
              }
            ]
          }
        ],
        activeOption: 0,
        anslib: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
        surList: [],
        resultAnswer: "",
        surDetails: [],
        selected: '',
        researchId: 0,
        openedModal:'',
        updateSurveyModal: false
      };
    },
    mounted() {
    },
    created() {
      this.surveyList();
    },
    methods: {
      regSur(){
        this.title= ""
        this.data= ""
        this.updateSurveyModal = false
        let item = {
          id: '',
          number:1,
          question: "",
          delete:false,
          type: 0,
          answers: [
            {
              answer: ""
            }
          ]
        }
        this.questions = []
        this.questions.push(item)
        UIkit.modal("#modalsurvey", {
          modal: false,
          keyboard: false,
          bgclose: false,
          center: true
        }).show();
      },
      addAnswer() {
        let ans = {
          number: this.anslib[this.questions[this.activeOption].answers],
          answers: []
        };
        if (this.questions[this.activeOption].answers.length > 9) {
          alert("too many answer");
        } else {
          this.questions[this.activeOption].answers.push(ans);
        }
      },
      removeAnswer(index) {
        if (this.questions[this.activeOption].answers.length < 2) {
          alert("At least one views");
          return;
        }
        this.questions[this.activeOption].answers.splice(index, 1 );
      },
      addTypeUpdate(){
        let item = {
          id: 0,
          number:this.questions[this.questions.length-1].number+1,
          question: "",
          type: 0,
          delete:false,
          answers: [
            {
              answer: ""
            }
          ]
        };
        this.questions.push(item);
        this.selectType(this.questions.length - 1);
      },
      addType() {
        let item = {
          id: '',
          number:this.questions[this.questions.length-1].number+1,
          question: "",
          type: 0,
          delete:false,
          answers: [
            {
              answer: ""
            }
          ]
        };
        if (this.questions.length > 9) {
          alert("max question is 10 ");
        } else {
          this.questions.push(item);
          this.selectType(this.questions.length - 1);
        }
      },
      removeType() {
        if (this.updateSurveyModal == true && this.questions[this.activeOption].id != 'undefined') {
          let newArr = {
            question: this.questions[this.activeOption].question,
            delete: true,
            id: this.questions[this.activeOption].id,
            number: this.questions[this.activeOption].number,
            type: this.questions[this.activeOption].type,
            answers: [
              {
                answer: ""
              }
            ]
          }
          this.questions.push(newArr)
        }
        this.questions.splice(this.activeOption, 1);
        this.selectType(this.questions.length - 1);
      },
      selectType(index) {
        this.activeOption = index;
      },
      async createSurvey() {
        try {
          let item = {
            title: this.title,
            data: this.data,
            questions: this.questions
          };
          const  response = await ResearchService.createSurvey(item);
          this.surveyList()
          if (response.data.status == 200) {
            alert(this.$t("successfullySurvey"))
          }
        } catch (error) {
          console.log(error);
        }
      },
      async surveyList() {
        try {
          const response = await ResearchService.getSurveyList();
          this.surList = response.data.content;
        } catch (error) {
          console.log(error);
        }
      },
      beforeDelete(item){
        this.selected = item.id
      },
      async deleteSur() {
        try {
          let array = []
          array.push(this.selected)
          const response = await ResearchService.deleteSurvey(array);
          if (response.data.status == 200) {
            alert(this.$t("deleteSurvey"))
            this.surveyList();
          }
        } catch (error) {
          console.log(error);
        }
      },
      async modifySurvey() {
        try {
          let item = {
            id: this.researchId,
            title: this.title,
            data: this.data,
            questions: this.questions
          };
          const response = await ResearchService.modifySurvey(item);
          if (response.data.status == 200) {
            alert(this.$t("modifySurvey"))
            window.location.reload()
          }
        } catch (error) {
          console.log(error);
        }
      },
      async updateSur(id) {
        try {
          this.updateSurveyModal = true
          this.researchId = id

          const response = await ResearchService.getSurveyDetails(id);
          let detail = response.data.result.researchDetail;
          if (response.data.status == 200) {

            UIkit.modal("#modalsurvey", {
              modal: false,
              keyboard: false,
              bgclose: false,
              center: true
            }).show();

            this.questions = [];
            this.title = detail.title;
            this.data = detail.memo;

            for (let a = 0; a < detail.questions.length; a++) {
              let question = detail.questions[a];
              let quesItem = {
                number:a+1,
                delete:question.delete,
                id: question.id,
                question: question.question,
                type: question.type
              };
              var answers = question.answers.split("||");
              let anses = [];
              for (let i = 0; i < answers.length; i++) {
                let answer = answers[i];
                let ans = {
                  answer: answer
                };
                anses.push(ans);
              }
              quesItem.answers = anses;
              this.questions.push(quesItem);
            }
          }
        } catch (error) {
          console.log(error);
        }
      },
      async surveyDetail(id) {
        try {
          const response = await ResearchService.getSurveyDetails(id);
          this.surDetails = response.data.result.researchDetail;
          if (response.data.status == 200) {
            console.log(this.surDetails);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
</script>
<style scoped>
  .uk-modal-dialog{
    border-radius: 15px;
  }
  .uk-modal-header{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .uk-modal-footer{
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .uk-modal-header .uk-modal-title{
    font-size: 23px;
    text-transform: capitalize;
    font-weight: 700;
    color: #0f7ae5;
  }
  .detailsStudent {
    border: 1px solid black;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .uk-table td {
    border-bottom: 1px solid #dee2fe;
    border-top: 1px solid #dee2fe;
    font-size: 13px;
    margin: 0px;
    text-transform: capitalize;
  }
  td p {
    border: 1px solid #dee2e6;
    padding-left: 9px;
    padding-top: 2px;
    border-radius: 50%;
    width: 17px;
    height: 23px;
    margin-left: 50px !important;
  }
  tr{
    border-color: #dee2e6;
  }
  td{
    border-color: #dee2e6;
  }
  .quesOrder{
    border: 1px solid #dee2e6;
    border-radius: 50%;
    padding: 3px;
    width: 27px;
    margin: 5px;
  }
  .surQuestion{
    margin: 0px;
    padding: 5px;
  }
  .surAnswer{
    padding: 3px;
    margin-left: 10px;
  }
  .qList {
    height: 20px;
    font-size: 1.2em;
    font-weight: 800;
    color: rgb(1, 176, 255);
  }
  .qbtn_d {
    background-color: #fff;
    display: inline-block;
    width: 100px;
    color: rgb(1, 176, 255);
    border-radius: 1em;
    border: 1px solid rgb(1, 176, 255);
    text-transform: capitalize;
    text-align: center;
  }
  .qbtn_b {
    background-color: #fff;
    display: inline-block;
    color: rgb(1, 176, 255);
    border: 1px solid rgb(1, 176, 255);
    border-radius: 1em;
    text-align: center;
    text-transform: capitalize;
  }

  .qbtn_b:hover {
    background-color: rgb(1, 176, 255);
    color: white;
  }
  .ultypes li a {
    display: block;
    border: 1px solid #0e4085;
    color: #0e4085;
    border-radius: 0px;
  }
  .add-type {
    border: 0.4px solid #94bdf7;
    border-radius: 0px;
  }
  .questionNumber {
    font-size: large;
    padding: 0px 10px;
    font-style: normal;
    font-weight: 500;
    margin: 0px;
    color: #011638;
  }
  .questions {
    font-size: 1.1em;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 600;
    padding-left: 0px;
  }
  .ultypes li.checked a {
    background: #0e4085;
    color: #fff;
  }
  /* switch */
  .uk-button-success {
    background-color: greenyellow;
    color: blue;
  }
  .uk-switch {
    position: relative;
    display: inline-block;
    height: 34px;
    width: 60px;
  }
  /* Hide default HTML checkbox */
  .uk-switch input {
    display: none;
  }
  /* Slider */
  .uk-switch-slider {
    background-color: rgba(0, 0, 0, 0.22);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 500px;
    bottom: 0;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 0.2s;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.07);
  }
  /* Switch pointer */
  .uk-switch-slider:before {
    content: "";
    background-color: #fff;
    position: absolute;
    width: 30px;
    height: 30px;
    left: 2px;
    bottom: 2px;
    border-radius: 50%;
    transition-property: transform, box-shadow;
    transition-duration: 0.2s;
  }

  /* Slider active color */
  input:checked + .uk-switch-slider {
    background-color: #39f !important;
  }
  /* Pointer active animation */
  input:checked + .uk-switch-slider:before {
    transform: translateX(26px);
  }
  /* chart */
  dl {
    display: flex;
    background-color: white;
    flex-direction: column;
    width: 100%;
    position: relative;
    border-bottom: 1px solid rgb(109, 107, 107);
  }
  dt {
    align-self: flex-start;
    width: 100%;
    font-weight: 700;
    display: block;
    font-size: 1.2em;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 10px;
    border-bottom: 1px solid black;
  }
  .text {
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 40px;
    background-color: white;
    position: absolute;
    left: 0;
    justify-content: flex-end;
  }
  .percentage {
    font-size: 0.8em;
    line-height: 1;
    text-transform: uppercase;
    height: 40px;
    margin-left: 170px;
    background: repeating-linear-gradient(
            to right,
            #ddd,
            #ddd 1px,
            #fff 1px,
            #fff 5%
    );
  }
  .percentage:after {
    content: "";
    display: block;
    background-color: #3456be;
    width: 50px;
    margin-bottom: 10px;
    height: 90%;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  .percentage:hover:after,
  .percentage:focus:after {
    background-color: #aaa;
  }
  .percentage-1:after {
    width: 1%;
  }
  .percentage-2:after {
    width: 2%;
  }
  .percentage-3:after {
    width: 3%;
  }
  .percentage-4:after {
    width: 4%;
  }
  .percentage-5:after {
    width: 5%;
  }
  .percentage-6:after {
    width: 6%;
  }
  .percentage-7:after {
    width: 7%;
  }
  .percentage-8:after {
    width: 8%;
  }
  .percentage-9:after {
    width: 9%;
  }
  .percentage-10:after {
    width: 10%;
  }
  .percentage-11:after {
    width: 11%;
  }
  .percentage-12:after {
    width: 12%;
  }
  .percentage-13:after {
    width: 13%;
  }
  .percentage-14:after {
    width: 14%;
  }
  .percentage-15:after {
    width: 15%;
  }
  .percentage-16:after {
    width: 16%;
  }
  .percentage-17:after {
    width: 17%;
  }
  .percentage-18:after {
    width: 18%;
  }
  .percentage-19:after {
    width: 19%;
  }
  .percentage-20:after {
    width: 20%;
  }
  .percentage-21:after {
    width: 21%;
  }
  .percentage-22:after {
    width: 22%;
  }
  .percentage-23:after {
    width: 23%;
  }
  .percentage-24:after {
    width: 24%;
  }
  .percentage-25:after {
    width: 25%;
  }
  .percentage-26:after {
    width: 26%;
  }
  .percentage-27:after {
    width: 27%;
  }
  .percentage-28:after {
    width: 28%;
  }
  .percentage-29:after {
    width: 29%;
  }
  .percentage-30:after {
    width: 30%;
  }
  .percentage-31:after {
    width: 31%;
  }
  .percentage-32:after {
    width: 32%;
  }
  .percentage-33:after {
    width: 33%;
  }
  .percentage-34:after {
    width: 34%;
  }
  .percentage-35:after {
    width: 35%;
  }
  .percentage-36:after {
    width: 36%;
  }
  .percentage-37:after {
    width: 37%;
  }
  .percentage-38:after {
    width: 38%;
  }
  .percentage-39:after {
    width: 39%;
  }
  .percentage-40:after {
    width: 40%;
  }
  .percentage-41:after {
    width: 41%;
  }
  .percentage-42:after {
    width: 42%;
  }
  .percentage-43:after {
    width: 43%;
  }
  .percentage-44:after {
    width: 44%;
  }
  .percentage-45:after {
    width: 45%;
  }
  .percentage-46:after {
    width: 46%;
  }
  .percentage-47:after {
    width: 47%;
  }
  .percentage-48:after {
    width: 48%;
  }
  .percentage-49:after {
    width: 49%;
  }
  .percentage-50:after {
    width: 50%;
  }
  .percentage-51:after {
    width: 51%;
  }
  .percentage-52:after {
    width: 52%;
  }
  .percentage-53:after {
    width: 53%;
  }
  .percentage-54:after {
    width: 54%;
  }
  .percentage-55:after {
    width: 55%;
  }
  .percentage-56:after {
    width: 56%;
  }
  .percentage-57:after {
    width: 57%;
  }
  .percentage-58:after {
    width: 58%;
  }
  .percentage-59:after {
    width: 59%;
  }
  .percentage-60:after {
    width: 60%;
  }
  .percentage-61:after {
    width: 61%;
  }
  .percentage-62:after {
    width: 62%;
  }
  .percentage-63:after {
    width: 63%;
  }
  .percentage-64:after {
    width: 64%;
  }
  .percentage-65:after {
    width: 65%;
  }
  .percentage-66:after {
    width: 66%;
  }
  .percentage-67:after {
    width: 67%;
  }
  .percentage-68:after {
    width: 68%;
  }
  .percentage-69:after {
    width: 69%;
  }
  .percentage-70:after {
    width: 70%;
  }
  .percentage-71:after {
    width: 71%;
  }
  .percentage-72:after {
    width: 72%;
  }
  .percentage-73:after {
    width: 73%;
  }
  .percentage-74:after {
    width: 74%;
  }
  .percentage-75:after {
    width: 75%;
  }
  .percentage-76:after {
    width: 76%;
  }
  .percentage-77:after {
    width: 77%;
  }
  .percentage-78:after {
    width: 78%;
  }
  .percentage-79:after {
    width: 79%;
  }
  .percentage-80:after {
    width: 80%;
  }
  .percentage-81:after {
    width: 81%;
  }
  .percentage-82:after {
    width: 82%;
  }
  .percentage-83:after {
    width: 83%;
  }
  .percentage-84:after {
    width: 84%;
  }
  .percentage-85:after {
    width: 85%;
  }
  .percentage-86:after {
    width: 86%;
  }
  .percentage-87:after {
    width: 87%;
  }
  .percentage-88:after {
    width: 88%;
  }
  .percentage-89:after {
    width: 89%;
  }
  .percentage-90:after {
    width: 90%;
  }
  .percentage-91:after {
    width: 91%;
  }
  .percentage-92:after {
    width: 92%;
  }
  .percentage-93:after {
    width: 93%;
  }
  .percentage-94:after {
    width: 94%;
  }
  .percentage-95:after {
    width: 95%;
  }
  .percentage-96:after {
    width: 96%;
  }
  .percentage-97:after {
    width: 97%;
  }
  .percentage-98:after {
    width: 98%;
  }
  .percentage-99:after {
    width: 99%;
  }
  .percentage-100:after {
    width: 100%;
  }
</style>
