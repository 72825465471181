import Api from '@/services/Api'

const resource = '/research'

export default {

  createSurvey(credentials) {
    return Api().post(`${resource}/create`, credentials)
  },
  modifySurvey(credentials) {
    return Api().post(`${resource}/modify`, credentials)
  },
  getSurveyList(params = {}) {
    return Api().get(`${resource}/list/?12`, {
      params: params
    })
  },
  getSurveyDetails(id) {
    return Api().get(`${resource}/${id}`)
  },
  deleteSurvey(credentials) {
    return Api().post(`${resource}/delete`, credentials)
  },
  surveyResultDetail(credentials) {
    return Api().post(`${resource}/result/detail`, credentials)
  },
  surveyResultList(params = {}) {
    return Api().get(`${resource}/result/list/?size=12`, {
      params: params
    })
  },
  surveyExamList(params = {}) {
    return Api().get(`${resource}/result/exams`, {
      params: params
    })
  },
  surveyExamDetail(credentials) {
    return Api().post(`${resource}/exam/result/detail`, credentials)
  },
  surveyResultDownload(credentials) {
    return Api().post(`${resource}/result/detail/excel`, credentials)
  },
  surveyResultDownloadExaminee(credentials) {
    return Api().post(`${resource}/result/detail/excelexaminee`, credentials)
  },
}
